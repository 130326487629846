
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
.App {
  text-align: center;

  overflow-x: hidden;
  text-align: center;
  /* background-color: whitesmoke;  */
  font-family: 'Inter', sans-serif;
}


/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */


a {
  color: inherit;
  text-decoration: none;
}

